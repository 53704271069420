import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mq } from '@/utils/helpers'
import { desktop, laptop, tablet, tabletL, tabletS, mobile } from '@/utils/media'
import { fonts } from '@/utils/preset'
import Typography from '@/components/common/Typography'
import ParticleBackground from '@/components/common/ParticleBackground'

const RecruitStatisticsList = ({ theme, ...other }) => (
  <StaticQuery
    query={query}
    render={data => {
      const list = data.list.edges.map(el => el.node)
      const first = list[0]
      return (
        <Root theme={theme} {...other}>
          <Grid theme={theme}>
            <Row>
              <Large theme={theme}>
                <Background scale={400} />
                <Content>
                  <Content_Title variant="h2" component="h3" theme={theme}>{first.name}</Content_Title>
                  <Content_Number theme={theme} css={styles.Large_Content_Number}>
                    {first.type === 'number' ? first.value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : first.value}
                    <small>{first.unit}</small>
                  </Content_Number>
                </Content>
              </Large>
            </Row>
            <Row>
              {list.slice(1).map((item, index) => (
                <Small theme={theme} key={index}>
                  <Background scale={400} />
                  <Content>
                    <Content_Title variant="h3" component="h3" theme={theme}>{item.name}</Content_Title>
                    <Content_Number theme={theme}>
                      {item.type === 'number' ? item.value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : item.value}
                      <small>{item.unit}</small>
                    </Content_Number>
                  </Content>
                </Small>
              ))}
            </Row>
          </Grid>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Grid = styled.div`
  border: 0.5px solid ${props => props.theme.text.default};
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Large = styled.div`
  position: relative;
  width: 100%;
  padding: 32px 92px 32px 32px;
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Small = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  @media ${mq.and(desktop, laptop)} {
    width: 50%;
  }
  padding: 24px 32px;
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
  border: 0.5px solid ${props => props.theme.text.default};
`

const Content = styled.div`
  @media ${mq.and(desktop, laptop, tablet)} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media ${mq.and(mobile)} {
    text-align: center;
  }
`

const Content_Title = styled(Typography)`
  position: relative;
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding-right: 16px;
  }
`

const Content_Number = styled.p`
  font-family: ${fonts.en};
  color: ${props => props.theme.primary.light};
  @media ${mq.and(desktop)} {
    font-size: 72px;
  }
  @media ${mq.and(laptop, tabletL)} {
    font-size: 72px;
  }
  @media ${mq.and(tabletS)} {
    font-size: 12vw;
  }
  @media ${mq.and(mobile)} {
    margin-top: 16px;
    font-size: 64px;
  }
  sup {
    font-size: 0.5em;
    vertical-align: super;
  }
  small {
    font-size: 0.6em;
  }
`

const styles = {
  Large_Content_Number: css`
    @media ${mq.and(desktop)} {
      font-size: 216px;
    }
    @media ${mq.and(laptop, tabletL)} {
      font-size: 160px;
    }
    @media ${mq.and(tabletS)} {
      font-size: 20vw;
    }
    @media ${mq.and(mobile)} {
      font-size: 96px;
    }
  `
}

export default ThemeConsumer(RecruitStatisticsList)

const query = graphql`
  query RecruitStatisticsListQuery {
    list: allRecruitStatisticsListYaml {
      edges {
        node {
          name
          value
          type
          unit
        }
      }
    }
  }
`
