import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-recruit.png'
import List from '@/components/modules/Recruit/Statistics/List'
import CompanyLead from '@/components/modules/Recruit/Company/Lead'
import InterviewLead from '@/components/modules/Recruit/Interview/Lead'
import Entry from '@/components/modules/Recruit/Entry'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import LinkButton1 from '@/components/common/LinkButton1'

const RecruitStatisticsPage = props => (
  <Root>
    <SEO location={props.location} title="数字で見るウィザード | 採用情報" description="株式会社ウィザードのエンジニアの割合・平均年齢・残業時間・平均勤続年数など、定量的なデータをご覧いただけます。"/>
    <Head>
      <PageTitle src={imgTitle}>数字で見るウィザード</PageTitle>
    </Head>
    <Main>
      <Row><List /></Row>
      <Row css={styles.Company}><CompanyLead /></Row>
      <Row css={styles.Interview}><InterviewLead /></Row>
      <Row css={styles.Entry}><Entry /></Row>
      <Row css={styles.Link}><Button to="/recruit/" component={Link} scale={800}>採用トップページ</Button></Row>
      <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
      <Row css={styles.Contact}><ContactLead /></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Company: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Interview: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Entry: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Link: css`
    text-align: center;
  `
}

export default PageBase(RecruitStatisticsPage)
